
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { Provider } from "@/modules/provider/provider.module";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getPanel } from "@/api/encounter.api";
import Multiselect from "@vueform/multiselect";
import State from "@/components/ABilling/StateComponent.vue";
import Country from "@/components/ABilling/CountryComponent.vue";
import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import {
  deleteProvider,
  getDefaultProvider,
  getProvider,
  updateProvider,
  updateTaxonomy,
  updateStateLicenseNumberByProviderId,
  getStateLicenseNumberByProviderId,
} from "@/api/provider.api";
import { useRoute, useRouter } from "vue-router";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Data {
  isDirty: boolean;
  provider?: Provider;
  addTaxonomyMode: boolean;
  taxonomyProperLength: boolean;
  taxonomiesDefaultSelected: boolean;
  stateLicensesDefaultSelected: boolean;
  stateLicenseStateSelected: boolean;
  addStateLicenseMode: boolean;
  taxonomyisDefault: boolean;
  disableOtherDefaultTaxonomy: boolean;
  disableOtherDefaultStateLicenses: boolean;
  stateLicenseisDefault: boolean;
  panel: { facilities: any[]; patients: any[]; providers: Provider[] };
  previousProvider?: Provider;
  primaryFacility: string;
  otherFacilitiesList: any[];
  createNewMode: boolean;
  taxonomies: [];
  stateLicenseNumbers: [];
  stateLicenseState: string;
  updateClicked: boolean;
  dict: {
    statuses: {
      code: string;
      name: string;
    }[];
  };
}

export default defineComponent({
  name: "Provider Page",
  components: {
    Multiselect,
    State,
    Country,
    PhoneNumberComponent,
    DateFloatComponent,
  },
  props: ["providerId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },

  setup(props) {
    let organizationId = ref<string | null>("");
    const route = useRoute();
    const router = useRouter();
    let data = reactive<Data>({
      isDirty: false,
      provider: undefined,
      addStateLicenseMode: false,
      addTaxonomyMode: false,
      taxonomyProperLength: true,
      taxonomiesDefaultSelected: false,
      stateLicensesDefaultSelected: false,
      stateLicenseStateSelected: true,
      createNewMode: true,
      panel: { facilities: [], patients: [], providers: [] },
      taxonomyisDefault: false,
      disableOtherDefaultTaxonomy: false,
      disableOtherDefaultStateLicenses: false,
      stateLicenseisDefault: false,
      taxonomies: [],
      stateLicenseNumbers: [],
      stateLicenseState: "",
      updateClicked: false,
      primaryFacility: "",
      otherFacilitiesList: [],
      dict: {
        statuses: [],
      },
    });

    watch(
      () => data.provider,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          oldValue &&
          JSON.stringify(currentValue) != JSON.stringify(oldValue)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    // watch for changes in new taxonomy
    watch(
      () => data.provider?.taxonomy,
      (currentValue, oldValue) => {
        if (currentValue) {
          if (!data.taxonomyisDefault) {
            var countDefault = 0;
            data.taxonomies.forEach((taxonomy) => {
              if (taxonomy && taxonomy["isDefault"]) {
                countDefault++;
              }
            });
            if (countDefault < 1 && !data.taxonomyisDefault) {
              data.taxonomiesDefaultSelected = false;
            } else {
              data.taxonomiesDefaultSelected = true;
            }
          }
        }
        if (
          data.disableOtherDefaultTaxonomy ||
          (data.provider?.taxonomy && data.provider.taxonomy.length < 1)
        ) {
          if (data.taxonomyisDefault) {
            data.disableOtherDefaultTaxonomy = false;
          }
          data.taxonomyisDefault = false;
        }
        if (
          ((data.provider?.taxonomy && data.provider.taxonomy.length < 1) ||
            !data.provider?.taxonomy) &&
          data.taxonomies.length < 1
        ) {
          data.taxonomyProperLength = true;
          data.taxonomiesDefaultSelected = true;
          data.taxonomyisDefault = false;
        }
        if (
          data.provider?.taxonomy &&
          data.provider.taxonomy.length > 0 &&
          data.taxonomies.length < 1
        ) {
          data.taxonomiesDefaultSelected = true;
          data.taxonomyProperLength = true;
          data.taxonomyisDefault = true;
        }
      },
      { deep: true }
    );

    // watch for changes in new stateLicenseNumber
    watch(
      () => data.provider?.stateLicenseNumber,
      (currentValue, oldValue) => {
        if (!data.stateLicenseisDefault) {
          var countDefault = 0;
          data.stateLicenseNumbers.forEach((stateLicenseNumber) => {
            if (stateLicenseNumber && stateLicenseNumber["isDefault"]) {
              countDefault++;
            }
          });
          if (countDefault < 1 && !data.stateLicenseisDefault) {
            data.stateLicensesDefaultSelected = false;
          } else {
            data.stateLicensesDefaultSelected = true;
          }
        }
        if (
          data.disableOtherDefaultStateLicenses ||
          (data.provider?.stateLicenseNumber &&
            data.provider.stateLicenseNumber.length < 1)
        ) {
          if (data.stateLicenseisDefault) {
            data.disableOtherDefaultStateLicenses = false;
          }
          data.stateLicenseisDefault = false;
        }
        if (
          ((data.provider?.stateLicenseNumber &&
            data.provider.stateLicenseNumber.length < 1) ||
            !data.provider?.stateLicenseNumber) &&
          data.stateLicenseNumbers.length < 1
        ) {
          data.stateLicensesDefaultSelected = true;
          data.stateLicenseisDefault = false;
        }
      },
      { deep: true }
    );

    // watch for changes in new stateLicenceState
    watch(
      () => data.stateLicenseState,
      () => {
        if (data.provider?.stateLicenseNumber) {
          if (!data.stateLicenseState) {
            data.stateLicenseStateSelected = false;
          } else {
            data.stateLicenseStateSelected = true;
          }
        } else {
          data.stateLicenseStateSelected = true;
        }
      },
      { deep: true }
    );

    watch(
      () => data.taxonomyisDefault,
      (currentValue, oldValue) => {
        if (currentValue) {
          data.taxonomiesDefaultSelected = true;
        } else {
          if (
            data.taxonomies.length < 1 &&
            data.provider?.taxonomy &&
            data.provider.taxonomy.length > 1
          ) {
            data.taxonomiesDefaultSelected = false;
          } else {
            var countDefault = 0;
            data.taxonomies.forEach((taxonomy) => {
              if (taxonomy && taxonomy["isDefault"]) {
                countDefault++;
              }
            });
            if (
              countDefault < 1 &&
              !currentValue &&
              data.provider?.taxonomy &&
              data.provider.taxonomy.length > 1
            ) {
              data.taxonomiesDefaultSelected = false;
            } else {
              if (currentValue) data.taxonomiesDefaultSelected = true;
              else if (data.taxonomies.length < 1) {
                data.taxonomiesDefaultSelected = true;
              }
            }
          }
        }
      },
      { deep: true }
    );

    watch(
      () => data.stateLicenseisDefault,
      (currentValue, oldValue) => {
        if (currentValue) {
          data.stateLicensesDefaultSelected = true;
        } else {
          if (
            data.stateLicenseNumbers.length < 1 &&
            data.provider?.stateLicenseNumber &&
            data.provider.stateLicenseNumber.length > 1
          ) {
            data.stateLicensesDefaultSelected = false;
          } else {
            var countDefault = 0;
            data.stateLicenseNumbers.forEach((stateLicenseNumber) => {
              if (stateLicenseNumber && stateLicenseNumber["isDefault"]) {
                countDefault++;
              }
            });
            if (
              countDefault < 1 &&
              !currentValue &&
              data.provider?.stateLicenseNumber &&
              data.provider.stateLicenseNumber.length > 1
            ) {
              data.stateLicensesDefaultSelected = false;
            } else {
              if (currentValue) data.stateLicensesDefaultSelected = true;
              else if (data.stateLicenseNumbers.length < 1)
                data.stateLicensesDefaultSelected = true;
            }
          }
        }
      },
      { deep: true }
    );

    // watch for changes in taxonomyList
    watch(
      () => data.taxonomies,
      (currentValue, oldValue) => {
        if (currentValue && data.taxonomies.length > 0) {
          if (!data.taxonomyisDefault) {
            var countDefault = 0;
            data.taxonomies.forEach((taxonomy) => {
              if (taxonomy && taxonomy["isDefault"]) {
                countDefault++;
              }
            });
            if (countDefault < 1 && !data.taxonomyisDefault) {
              data.taxonomiesDefaultSelected = false;
            } else {
              data.taxonomiesDefaultSelected = true;
            }
          }
          data.taxonomies.forEach((taxonomy) => {
            var value = taxonomy["value"] as any;
            var isDefault = taxonomy["isDefault"] as any;
            if (value.length < 1 && isDefault) {
              taxonomy["isDefault"] = false as never;
              data.disableOtherDefaultTaxonomy = false;
            }
          });
        }
      },
      { deep: true }
    );

    // watch for changes in stateLicenseNumbers list
    watch(
      () => data.stateLicenseNumbers,
      (currentValue, oldValue) => {
        if (currentValue && data.stateLicenseNumbers.length > 0) {
          if (!data.stateLicenseisDefault) {
            var countDefault = 0;
            data.stateLicenseNumbers.forEach((stateLicenseNumber) => {
              if (stateLicenseNumber && stateLicenseNumber["isDefault"]) {
                countDefault++;
              }
            });
            if (countDefault < 1 && !data.stateLicenseisDefault) {
              data.stateLicensesDefaultSelected = false;
            } else {
              data.stateLicensesDefaultSelected = true;
            }
          }
          data.stateLicenseNumbers.forEach((stateLicenseNumber) => {
            var value = stateLicenseNumber["value"] as any;
            var isDefault = stateLicenseNumber["isDefault"] as any;
            if (value.length < 1 && isDefault) {
              stateLicenseNumber["isDefault"] = false as never;
              data.disableOtherDefaultStateLicenses = false;
            }
          });
        }
      },
      { deep: true }
    );

    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // eslint-disable-line
    const postalRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    const npiReg = helpers.regex(/\d{10}/);
    const taxIdReg = helpers.regex(/\d{9}/);

    const billingReq = (param) =>
      helpers.withParams(
        { type: "billingReq", value: param },
        (value) => helpers.req(value) || !param.provider?.billing
      );

    const phoneReq = (param) =>
      helpers.withParams(
        { type: "phoneReq", value: param },
        (value) =>
          (helpers.req(value) && (value as string).match(phoneRegex)) ||
          !param.provider?.billing
      );

    const postalReq = (param) =>
      helpers.withParams(
        { type: "postalReq", value: param },
        (value) =>
          (helpers.req(value) && (value as string).match(postalRegex)) ||
          !param.provider?.billing
      );

    const providerRules = {
      provider: {
        firstName: { required: helpers.withMessage("Required", required) },
        lastName: { required: helpers.withMessage("Required", required) },
        npi: {
          required: helpers.withMessage("Required", required),
          npiReg: helpers.withMessage("10 numbers required", npiReg),
          maxLength: maxLength(10),
        },
        status: { required: helpers.withMessage("Required", required) },
        taxId: {
          npiReg: helpers.withMessage("9 numbers required", taxIdReg),
          maxLength: maxLength(9),
        },
        address: {
          mustBeCool: helpers.withMessage("Required", billingReq(data)),
        },
        city: {
          mustBeCool: helpers.withMessage("Required", billingReq(data)),
        },
        state: {
          mustBeCool: helpers.withMessage("Required", billingReq(data)),
        },
        country: {
          mustBeCool: helpers.withMessage("Required", billingReq(data)),
        },
        zip: {
          mustBeCool: helpers.withMessage(
            "Postal code must be 5 digits or 9 digits, and in ######-#### format",
            postalReq(data)
          ),
          maxLength: maxLength(10),
        },
        phone: {
          required: helpers.withMessage("Required", required),
          mustBeCool: helpers.withMessage(
            "Phone number is invalid",
            phoneReq(data)
          ),
        },
      },
    };

    let v$ = useVuelidate(providerRules, data as never);

    const breadcrumbPath = decodeURIComponent(
      String(route.query?.breadcrumb ?? "back")
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Edit Provider");
      data.panel = await getPanel();
      data.dict.statuses = [
        { code: "active", name: "Active" },
        { code: "inactive", name: "Inactive" },
      ];
      if (props.providerId) {
        data.provider = await getProvider(props.providerId);
        data.createNewMode = false;

        if (data.provider.taxonomy) {
          data.taxonomies = getListFromValue(data.provider.taxonomy);
          updateDefaultTaxonomies(data);
          data.provider.taxonomy = "";
        }

        // call get state license number for list
        // if there are state license numbers, update UI to display all state license numbers
        data.stateLicenseNumbers = await getStateLicenseNumberByProviderId(
          data.provider.id
        );
        if (data.stateLicenseNumbers.length > 0) {
          updateDefaultStateLicenses(data);
          data.provider.stateLicenseNumber = "";
        }
        if (data.provider.primaryFacility)
          data.primaryFacility = data.provider.primaryFacility;
        if (data.provider.otherFacility)
          data.otherFacilitiesList = JSON.parse(
            data.provider.otherFacility.toString()
          );
        if (
          !data.taxonomiesDefaultSelected &&
          data.taxonomies.length == 0 &&
          !data.provider?.taxonomy
        ) {
          data.taxonomiesDefaultSelected = true;
        }
        if (
          !data.stateLicensesDefaultSelected &&
          data.stateLicenseNumbers.length == 0 &&
          !data.provider?.stateLicenseNumber
        ) {
          data.stateLicensesDefaultSelected = true;
        }
      } else {
        data.createNewMode = true;
        data.provider = {
          id: "",
          firstName: "",
          lastName: "",
          npi: "",
          status: "active",
          zip: null,
          isDefault: false,
          state: null,
          country: "1",
          otherFacility: [],
          primaryFacility: "",
        };
      }

      data.previousProvider = JSON.parse(JSON.stringify(data.provider));
      data.updateClicked = false;
    });

    async function updateDefaultTaxonomies(data) {
      let count = 0;
      let index = 0;
      for (let i = 0; i < data.taxonomies.length; i++) {
        var isDefault = data.taxonomies[i]["isDefault"];
        if (isDefault) {
          data.taxonomiesDefaultSelected = true;
          data.disableOtherDefaultTaxonomy = true;
          index = i;
          count++;
        }
      }
      if (count > 1) {
        data.taxonomies[index].isDefault = false;
      }
    }

    function updateDefaultStateLicenses(data) {
      let count = 0;
      let index = 0;
      for (let i = 0; i < data.stateLicenseNumbers.length; i++) {
        var isDefault = data.stateLicenseNumbers[i]["isDefault"];
        if (isDefault) {
          data.disableOtherDefaultStateLicenses = true;
          index = i;
          count++;
        }
      }
      if (count > 1) {
        data.stateLicenseNumbers[index].isDefault = false;
      }
    }

    async function cancel() {
      if (route.path.includes("providerPageAfterEncounter")) {
        router.go(-1);
      } else {
        router.push(breadcrumbPath);
      }
    }

    async function updateTaxonomies() {
      await addTaxonomy(true);
      var taxonomyList = data.taxonomies;
      var length = taxonomyList.length;
      if (length == 0) return;
      for (let i = 0; i < length; i++) {
        var value = taxonomyList[i]["value"];
        if (value && Object.keys(value).length == 10) {
          var isDefault = data.taxonomies[i]["isDefault"];
          await addTaxonomyWithValue(value, isDefault, taxonomyList);
        }
      }
      updateDefaultTaxonomies(data);
      data.addTaxonomyMode = true;
    }

    async function updateStateLicenses() {
      addStateLicense(true);
      var stateLicenseList = data.stateLicenseNumbers;
      var length = stateLicenseList.length;
      var stringList = "";
      for (let i = 0; i < length; i++) {
        let value = stateLicenseList[i]["value"];
        let id = stateLicenseList[i]["id"];
        if (value) {
          let state = data.stateLicenseNumbers[i]["state"];
          let isDefault = data.stateLicenseNumbers[i]["isDefault"];
          stringList = addStateLicenseWithValue(
            value,
            id,
            state,
            isDefault,
            stateLicenseList
          );
        }
      }

      await updateStateLicenseNumberByProviderId(data.provider?.id, stringList);
      updateDefaultStateLicenses(data);
    }

    async function updatePostalToFormat(postal) {
      if (postal && data.provider?.zip) {
        data.provider.zip.replace("-", "");
        var zip = data.provider.zip.toString();
        if (zip.length == 5 && zip.substring(5, 9) != "0000") {
          data.provider.zip = zip + "-0000";
        } else if (zip.length == 9) {
          data.provider.zip = zip.substring(0, 5) + "-" + zip.substring(5, 9);
        }
      }
    }

    function disableOtherTaxonomiesAndUpdateVariables(value) {
      if (value == undefined && data.taxonomies.length > 0)
        data.disableOtherDefaultTaxonomy = !data.disableOtherDefaultTaxonomy;
      else if (value) {
        data.disableOtherDefaultTaxonomy = true;
      } else {
        data.disableOtherDefaultTaxonomy = false;
      }
      if (
        (!data.provider?.taxonomy || data.provider.taxonomy.length < 1) &&
        data.taxonomyisDefault
      ) {
        if (data.taxonomies.length > 0 && data.provider?.taxonomy) {
          data.taxonomiesDefaultSelected = true;
        }
        data.taxonomyisDefault = false;
        data.disableOtherDefaultTaxonomy = false;
      }
    }

    function disableOtherStateLicenseNumbersAndUpdateVariables(value) {
      if (value == undefined && data.stateLicenseNumbers.length > 0)
        data.disableOtherDefaultStateLicenses =
          !data.disableOtherDefaultStateLicenses;
      else if (value) {
        data.disableOtherDefaultStateLicenses = true;
      } else {
        data.disableOtherDefaultStateLicenses = false;
      }
      if (
        (!data.provider?.stateLicenseNumber ||
          data.provider.stateLicenseNumber.length < 1) &&
        data.stateLicenseisDefault
      ) {
        if (
          data.stateLicenseNumbers.length > 0 &&
          data.provider?.stateLicenseNumber
        ) {
          data.stateLicensesDefaultSelected = true;
        }
        data.stateLicenseisDefault = false;
        data.disableOtherDefaultStateLicenses = false;
      }
    }

    async function addTaxonomy(initiateUpdate) {
      var newTaxonomy = {};
      newTaxonomy["value"] = data.provider?.taxonomy;
      newTaxonomy["isDefault"] = data.taxonomyisDefault;
      var taxonomyList = data.taxonomies;
      if (data.provider?.taxonomy) data.provider.taxonomy = "";
      data.addTaxonomyMode = true;
      data.taxonomyisDefault = false;
      if (newTaxonomy["value"] !== "") taxonomyList.push(newTaxonomy as never);
      if (
        data.taxonomiesDefaultSelected &&
        data.provider?.taxonomy &&
        data.provider?.taxonomy.length == 10 &&
        initiateUpdate
      ) {
        var stringList = JSON.stringify(taxonomyList);
        await updateTaxonomy(data.provider?.id, stringList);
      } else if (
        data.provider?.taxonomy &&
        data.provider?.taxonomy.length != 0
      ) {
        data.taxonomyProperLength = false;
      }

      updateDefaultTaxonomies(data);
    }

    async function addTaxonomyWithValue(value, isDefault, list) {
      var newTaxonomy = {};
      newTaxonomy["value"] = value;
      newTaxonomy["isDefault"] = isDefault;
      var taxonomyList = [];
      for (let i = 0; i < list.length; i++) {
        var newValue = list[i]["value"];
        var newIsDefault = list[i]["isDefault"];
        if (newValue != value || newIsDefault != isDefault) {
          taxonomyList.push(list[i] as never);
        }
      }
      taxonomyList.push(newTaxonomy as never);
      var stringList = JSON.stringify(taxonomyList);
      await updateTaxonomy(data.provider?.id, stringList);
      data.addTaxonomyMode = true;
      data.taxonomyisDefault = false;
    }

    function addStateLicenseWithValue(value, id, state, isDefault, list) {
      var newStateLicense = {};
      newStateLicense["value"] = value;
      newStateLicense["isDefault"] = isDefault;
      newStateLicense["state"] = state;
      newStateLicense["id"] = id;
      var stateLicenseList = [];
      for (let i = 0; i < list.length; i++) {
        var newValue = list[i]["value"];
        var newIsDefault = list[i]["isDefault"];
        var newState = list[i]["state"];
        if (
          newValue != value ||
          newIsDefault != isDefault ||
          newState != state
        ) {
          stateLicenseList.push(list[i] as never);
        }
      }
      stateLicenseList.push(newStateLicense as never);
      // add Id to state license in statelicence list
      var stringList = JSON.stringify(stateLicenseList);
      data.addStateLicenseMode = false;
      data.stateLicenseisDefault = false;
      return stringList;
    }

    function addStateLicense(initiateUpdate) {
      var stateLicenseNumber = {};
      stateLicenseNumber["value"] = data.provider?.stateLicenseNumber;
      stateLicenseNumber["isDefault"] = data.stateLicenseisDefault;
      stateLicenseNumber["state"] = data.stateLicenseState;
      var stateLicenseNumberList = data.stateLicenseNumbers;
      if (data.provider?.stateLicenseNumber)
        data.provider.stateLicenseNumber = "";
      data.stateLicenseState = "";
      data.stateLicenseisDefault = false;
      data.addStateLicenseMode = true;
      if (stateLicenseNumber["value"] != "")
        stateLicenseNumberList.push(stateLicenseNumber as never);
      updateDefaultStateLicenses(data);
    }

    async function deleteStateLicense(value, isDefault, state) {
      var toBeDeletedStateLicense = {};
      toBeDeletedStateLicense["value"] = value;
      toBeDeletedStateLicense["isDefault"] = isDefault;
      toBeDeletedStateLicense["state"] = state;
      var stateLicenseList = data.stateLicenseNumbers;
      stateLicenseList = removeStateLicenseItem(
        stateLicenseList,
        toBeDeletedStateLicense
      );
      if (
        ((data.provider?.stateLicenseNumber &&
          data.provider.stateLicenseNumber.length < 1) ||
          !data.provider?.stateLicenseNumber) &&
        data.stateLicenseNumbers.length < 1
      ) {
        data.stateLicensesDefaultSelected = true;
      }
    }

    async function deleteTaxonomy(value, isDefault) {
      var toBeDeletedTaxonomy = {};
      toBeDeletedTaxonomy["value"] = value;
      toBeDeletedTaxonomy["isDefault"] = isDefault;
      if (isDefault == "true") {
        data.disableOtherDefaultTaxonomy = false;
      }
      var taxonomyList = data.taxonomies;
      taxonomyList = removeTaxonomyItem(taxonomyList, toBeDeletedTaxonomy);
      var stringList = JSON.stringify(taxonomyList);

      if (
        !data.taxonomiesDefaultSelected &&
        data.taxonomies.length == 0 &&
        !data.provider?.taxonomy
      ) {
        data.taxonomiesDefaultSelected = true;
      }
      await updateTaxonomy(data.provider?.id, stringList);

      if (
        ((data.provider?.taxonomy && data.provider.taxonomy.length < 1) ||
          !data.provider?.taxonomy) &&
        data.taxonomies.length < 1
      ) {
        data.stateLicensesDefaultSelected = true;
        data.taxonomyProperLength = true;
      }
    }

    function removeTaxonomyItem(array, item) {
      for (var i in array) {
        if (
          array[i]["value"] == item["value"] &&
          array[i]["isDefault"] == item["isDefault"]
        ) {
          array.splice(i, 1);
          return array;
        }
      }
      return array;
    }

    function removeStateLicenseItem(array, item) {
      for (var i in array) {
        if (
          array[i]["value"] == item["value"] &&
          array[i]["isDefault"] == item["isDefault"] &&
          array[i]["state"] == item["state"]
        ) {
          array.splice(i, 1);
          return array;
        }
      }
      return array;
    }

    function getListFromValue(list) {
      return JSON.parse(list);
    }

    async function updateItem() {
      data.updateClicked = true;
      if (
        data.taxonomiesDefaultSelected &&
        data.stateLicensesDefaultSelected &&
        data.stateLicenseStateSelected
      ) {
        var taxonomyClean = true;
        data.taxonomies.forEach(function (taxonomy) {
          if (Object.keys(taxonomy["value"]).length != 10)
            taxonomyClean = false;
          if (
            data.provider?.taxonomy &&
            data.provider?.taxonomy.length != 10 &&
            data.provider?.taxonomy.length != 0
          )
            taxonomyClean = false;
        });
        var statelicenseClean = true;
        data.stateLicenseNumbers.forEach(function (stateLicenseNumber) {
          if (Object.keys(stateLicenseNumber["value"]).length < 1)
            statelicenseClean = false;
          if (
            data.provider?.stateLicenseNumber &&
            data.provider?.stateLicenseNumber.length < 1
          )
            statelicenseClean = false;
          if (!stateLicenseNumber["state"]) {
            statelicenseClean = false;
          }
        });
        if (
          data.stateLicenseNumbers.length < 1 &&
          (!data.stateLicenseState || data.stateLicenseState == "") &&
          data.provider?.stateLicenseNumber &&
          data.provider?.stateLicenseNumber.length > 1
        ) {
          statelicenseClean = false;
        }

        const result = await v$.value.provider.$validate();
        if (
          result &&
          taxonomyClean &&
          data.taxonomyProperLength &&
          statelicenseClean
        ) {
          Swal.fire({
            title: "Would you like to apply these changes to all Open Claims?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.isConfirmed && data.provider) {
              data.provider.updateClaimsEvent = true;
            } else if (data.provider) {
              data.provider.updateClaimsEvent = false;
            }
            await updatePrimaryFacility();
            await updateOtherFacility();
            await updateStateLicenses();
            await updateTaxonomies();
            await updateProvider(data.provider);
            data.isDirty = false;

            if (route.path.includes("providerPageAfterEncounter")) {
              router.go(-1);
            } else {
              router.push(breadcrumbPath);
            }
          });
        }
      }
    }

    async function updatePrimaryFacility() {
      var provider;
      provider = data.provider;
      provider.primaryFacility = data.primaryFacility;
      data.provider = provider;
    }

    async function updateOtherFacility() {
      var provider;
      provider = data.provider;
      provider.otherFacility = JSON.stringify(data.otherFacilitiesList);
      data.provider = provider;
    }

    async function deleteItem() {
      await deleteProvider(data.provider);
    }

    async function selectDefault(event) {
      if (data.provider && !data.provider.isDefault) {
        Swal.fire({
          title:
            "Are you sure you want to remove this Provider as the default value?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        }).then((result) => {
          if (!result.isConfirmed && data.provider) {
            data.provider.isDefault = true;
          }
        });
      } else {
        let text = "Are you sure you want to set this Provider as default?";
        const defaultProvider = await getDefaultProvider();
        if (defaultProvider) {
          text =
            text +
            " Current default Provider Billing Provider is " +
            defaultProvider.lastName +
            " " +
            defaultProvider.firstName;
        }
        Swal.fire({
          title: text,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        }).then((result) => {
          if (!result.isConfirmed && data.provider) {
            data.provider.isDefault = false;
          }
        });
      }
    }

    function checkBilling(event) {
      if (data.provider && !data.provider.billing && data.provider.isDefault) {
        Swal.fire({
          title:
            "Are you sure you want to remove this Provider as the default value?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        }).then((result) => {
          if (data.provider) {
            if (!result.isConfirmed) {
              data.provider.isDefault = true;
              data.provider.billing = true;
            } else {
              data.provider.isDefault = false;
            }
          }
        });
      }
    }

    return {
      getListFromValue,
      organizationId,
      data,
      disableOtherTaxonomiesAndUpdateVariables,
      disableOtherStateLicenseNumbersAndUpdateVariables,
      updatePostalToFormat,
      addTaxonomy,
      addStateLicense,
      updateItem,
      deleteItem,
      deleteTaxonomy,
      deleteStateLicense,
      cancel,
      checkBilling,
      selectDefault,
      updatePrimaryFacility,
      v$,
    };
  },
});
